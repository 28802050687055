<template>
    <div class="bg re_sizing re_relative">
        <div class="title re_relative">
            <van-icon @click="goBack" class="icon re_color_white" name="arrow-left" size="22" />
        </div>
        <div class="appIcon">
            <img @click="goNext(1)" src="../../../assets/list/appIcon11.png" alt="">
            <img @click="goNext(2)" src="../../../assets/list/appIcon2.png" alt="">        
        </div>
    </div>
</template>

<script setup>
var surl = window.location.href;

var obj = {};
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
 obj[temp[0]] = temp[1];
}
console.log(obj)
obj.token = obj.token.replace(' ', '%20')
const goNext = async (type) => {
    let url = null
    let env = 'prod'
    switch(process.env.VUE_APP_API_URL){
        case 'https://kshtest.lanlingcb.com/dsgc':
            env = 'test'
            break;
        case 'https://llcb.kaishuihu.com/dsgc':
            env = 'prod'
            break;
        case 'https://prellcb.kaishuihu.com/dsgc':
            env = 'pre'
            break;
    }
    if(process.env.VUE_APP_API_URL === 'https://llcb.kaishuihu.com/dsgc'){
        if(type == 1){
            url = 'https://www.kaishuihu.com/newYear/index.html#/red/index/index?user_id='+obj.user_id+'&token='+obj.token
            window.location.href = url
        }else{
            url = 'https://www.kaishuihu.com/h5/Red_packet_rain/index.html#?user_id='+obj.user_id+'&token='+obj.token+'&env='+env
            goRain(url)
        }
    }else{
        if(type == 1){
            url = 'https://demo.lanlingcb.com/newYear/index.html#/red/index/index?user_id='+obj.user_id+'&token='+obj.token
            window.location.href = url
        }else{
            url = 'https://demo.lanlingcb.com/h5/Red_packet_rain/index.html#?user_id='+obj.user_id+'&token='+obj.token+'&env='+env
            goRain(url)
        }
    }
}

const goRain = (url) => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isAndroid) {
        android.toRedBagRain(url)
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage({
            redBag: 'toRedBagRain',
            url: url
        })
    }
}
const goBack = () => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isAndroid) {
        android.finish()
    }
    if (isiOS) {
        window.webkit.messageHandlers.AppModel.postMessage('finish')
    }
}
</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/list/appBg.png') left top no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .title {
        padding: 50px 0 12px 0;
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 10px;
        }
    }
    .appIcon{
        position: relative;
        top: 40vh;
    }
    img {
        width: 343px;
        height: 136px;
        margin-top: 12px;
    }
}
</style>
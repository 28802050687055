import { post } from '@/utils/http'
// 用户实名认证状态
export const sendRegisterSmsCode = function (data) {
    return post({
        url: "/oauth/sendRegisterSmsCode",
        data
    })
}

// 注册
export const codeRegister = function (data) {
    return post({
        url: "/oauth/codeRegister",
        data
    })
}

// 获取微信分享参数
export const shareConfig = function (data) {
    return post({
        url: "/oauth/inviteRegisterShareConfig",
        data
    })
}

// 获取微信分享参数
export const briefInfo = function (data) {
    return post({
        url: "/oauth/briefInfo",
        data
    })
}

<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>活动规则</span>
        </div>
        <div class="content" v-html="ruleInfo.ruleContent"> </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { getInfo } from './api.js'
import { ref, onMounted } from 'vue';
const ruleInfo = ref({});
const router = useRouter()
const goBack = () => {
    router.go(-1)
}
onMounted(() => {
    getInfo().then(res => {
        ruleInfo.value = res.data;
    })
})
</script>

<style lang="less" scoped>
.bg {
    padding-top: 50px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .content{
        padding: 24px;
        color: #333;
        text-align: left;
    }
}
</style>
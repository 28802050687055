<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18 re_bg_white">
            <van-icon @click="goBack(3)" class="icon" name="arrow-left" size="22" />
            <span>活动提现</span>
        </div>
        <div  @click="goBack(1)" class="re_bg_white content re_flex_between">
            <div class="re_flex re_flex_align">
                <img src="../../../assets/list/icon1.png" alt="">
                <span class="re_margin_l_16 re_font_16 re_color_normal re_font_bold">注册邀请/拜年红包活动提现</span>
            </div>
            <van-icon name="arrow" size="18" />

        </div>
        <div  @click="goBack(2)" style="border-top:none" class="re_bg_white content re_flex_between">
            <div class="re_flex re_flex_align">
                <img src="../../../assets/list/icon2.png" alt="">
                <span class="re_margin_l_16 re_font_16 re_color_normal re_font_bold">组局活动提现</span>
            </div>
            <van-icon name="arrow" size="18" />

        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
var surl = window.location.href;

var obj = {};
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
 obj[temp[0]] = temp[1];
}
const goBack = (type) => {
    if(type == 1){
        let url = null
        if(process.env.VUE_APP_API_URL === 'https://llcb.kaishuihu.com/dsgc'){
            url = 'https://www.kaishuihu.com/newYear/index.html#/red/my/money?user_id='+obj.user_id+'&token='+obj.token
        }else{
            url = 'https://demo.lanlingcb.com/newYear/index.html#/red/my/money?user_id='+obj.user_id+'&token='+obj.token
        }
        window.location.href = url
    }else if(type == 2){
        router.push('/activity')
    }else{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            appJsImp.goBack()   // 返回
        }
        if (isiOS) {
            window.webkit.messageHandlers.AppModel.postMessage('goBack');
        }
    }
}
</script>

<style lang="less" scoped>
.bg {
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #F7F7FA;
    .title {
        padding: 50px 0 12px 0;
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 54px;
        }
    }
    .content{
        padding: 0 24px;
        color: #333;
        text-align: left;
        height: 64px;
        border-top: 10px solid #F7F7FA;
        border-bottom: 1px solid #F7F7FA;
        background-color: #fff;
        img{
            width: 46px;
            height: 46px;
        }
    }
}

</style>
<template>
  <div class="re_min_page re_sizing re_paddingtb_24 re_paddinglr_16">
    <div class="noneImg" v-if="!infodata.photoUrl && loadingEnd">
      <img src="https://tp.kaishuihu.com/dsgc/icon/static/image/none.png" alt="">
      <p class="re_color_second">无法查看该内容或该内容已删除</p>
    </div>
    <template v-else-if="loadingEnd">
      <div class="top re_width100 re_flex re_flex_align">
        <img class="avatar" :src="infodata.photoUrl" alt="" />
        <div class="re_margin_l_8">
          <div class="title re_ellipsis re_font_14 re_font_bold">
            {{infodata.userName}}
          </div>
          <span class="re_color_light re_font_12">{{infodata.createTime}}</span>
        </div>
      </div>
      <div ref="content" v-if="showAll && infodata.content" class="content re_margin_top_16">
        {{ infodata.content }}
        <div
          v-if="infodata.content.length > 100"
          @click="showAllText(2)"
          class="re_color_active"
        >
          收起
        </div>
      </div>
      <div ref="content" v-if="!showAll && infodata.content" class="content re_margin_top_16">
        {{ newStr }}
        <span @click="showAllText(1)" class="re_color_active">全文</span>
      </div>
      <div v-if="infodata.videoUrl" class="re_margin_top_16">
        <video controls :poster="infodata.videoPic" :src="infodata.videoUrl"></video>
      </div>
      <div class="re_relative" v-if="infodata.partyInfo">
        <img class="re_margin_top_16 partyInfo" :src="infodata.partyInfo.pic" alt="">
        <div class="picText re_absolute re_flex re_flex_align re_flex_justify re_sizing">
          <span class="re_font_14 re_color_white">{{infodata.partyInfo.picText}}</span>
        </div>
      </div>
      <div v-else-if="infodata.pic.length > 1" class="fileBox re_margin_top_16">
        <template v-for="(item,index) in infodata.pic" :key="index">
          <img
            @click="showBig(index)"
            v-if="item != 'a'"
            :class="infodata.pic.length == 1 ? 'imgOne' : ''"
            :src="item.pic"
            alt=""
          />
          <div v-if="item == 'a'"></div>
        </template>
      </div>
      <div v-else-if="infodata.pic.length == 1" class="re_margin_top_16">
        <template v-for="(item,index) in infodata.pic" :key="index">
          <img
            @click="showBig(index)"
            v-if="item != 'a'"
            :class="infodata.pic.length == 1 ? 'imgOne' : ''"
            :src="item.pic"
            alt=""
          />
          <div v-if="item == 'a'"></div>
        </template>
      </div>
      
      <wx-open-launch-app
        id="launch-btn"
        appid="wxf1c9654e06a8af78"
        :extinfo="goAppStr"
      >
        <component :is="'script'" v-bind="{type:'text/wxtag-template'}">
            <div style="display:flex;flex-direction: row-reverse;margin-top:16px;width:355px">
              <img style="width:210px;height:24px" src="https://tp.kaishuihu.com/dsgc/icon/static/liuliuTip2.png" alt="">
            </div>
        </component>
      </wx-open-launch-app>

      <div class="comments" v-if="infodata.replyList.length">
        <div class="re_font_18 re_font_bold re_margin_bot_16">最新评论</div>
        <div v-for="(item,index) in infodata.replyList" :key="index" class="con">
          <template v-if="item.replyType == 1">
            <span class="nickName re_font_14 re_color_active">{{item.replerName}}：</span>
            <span class="re_font_14">{{item.reply}}</span>
          </template>
          <template v-else-if="item.replyType == 2">
            <span class="nickName re_font_14 re_color_active">{{item.replerName}}</span>
            <span class="re_font_14 re_color_light">回复</span>
            <span class="nickName re_font_14 re_color_active">{{item.ownerName}}：</span>
            <span class="re_font_14">{{item.reply}}</span>
          </template>
        </div>
      </div>
    </template>

    
  </div>
</template>

<script setup>

import { info, shareConfig, shareMsg } from './api.js';
import { ref, onMounted } from 'vue';
import { showImagePreview  } from 'vant';

const newStr = ref('');
const showAll = ref(false);
const loadingEnd = ref(false);
const contentStr = ref('');
const goAppStr = ref('');
const infodata = ref({});
// const videoStr = ref('http://tp.kaishuihu.com/videos/202407104HC0CFIMG_1749.MOV');
setTimeout(() => {
  goAppStr.value = JSON.stringify({
    android:'com.hnpp.moments.MomentDetailActivity',
    id: sessionStorage.getItem('postId') ? sessionStorage.getItem('postId') : '',
    ios: 'MyPaiDetailViewController'
  })
}, 1000);
function showBig(index){
  let bigArr = []
  infodata.value.pic.forEach(item=>{
    bigArr.push(item.pic)
  })
  showImagePreview({
    images: bigArr,
    closeable: true,
    startPosition: index 
  });
}

getSign()
function getSign(){
  shareConfig().then(res =>{
    if(res.code == 1){
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: res.data.appid, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'onShareTimeline'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      wx.ready(function () {
        getShare()
      })
      wx.error(function (res) {
        console.log(res,'rererer')
      })
      var btn = document.getElementById('launch-btn');
      btn.addEventListener('launch', function (e) {
        console.log(e)
      });
      btn.addEventListener('error', function (e) {
        window.location.href = 'http://www.kaishuihu.com/download/download.html'
    });
    }
  })
}
function getShare() {
  shareMsg({
    accessType: 4
  }).then(res=> {
    if(res.code == 1){
      wx.onMenuShareAppMessage({
        title: res.data.title,
        desc: res.data.msg ,
        link: res.data.link,
        imgUrl: res.data.icon,
        success: function (res) {
          /*shared(shareLink, "friend", shareGid);*/
          // alert("分享给朋友成功！");
        },
        fail: function (res) {
          // alert("分享给朋友失败！" + JSON.stringify(res));
        }
      });
      wx.onShareTimeline({
        title: res.data.title,
        desc: res.data.msg,
        link: res.data.link,
        imgUrl: res.data.icon,
        success: function (res) {
          /*shared(shareLink, "friend", shareGid);*/
          // alert("分享给朋友成功！");
        },
        fail: function (res) {
          // alert("分享给朋友失败！" + JSON.stringify(res));
        }
      });
    }
  })
}
function getStr() {
  if (infodata.value.pic.length == 2) {
    infodata.value.pic.push('a');
  }else if (infodata.value.pic.length == 4) {
    infodata.value.pic.splice(2, 0,'a')
    infodata.value.pic.push('a');
  }
  if (infodata.value.content.length < 100) {
    showAll.value = true;
  } else {
    showAll.value = false;
  }
  newStr.value = infodata.value.content.substring(0, 100) + '...';
  console.log(showAll.value)
}
function showAllText(type) {
  if (type == 1) {
    showAll.value = true;
  } else {
    showAll.value = false;
  }
}
getInfo()
function getInfo() {
  info().then(res =>{
    loadingEnd.value = true
    if(res.code == 1){
      infodata.value = res.data
      getStr();
    }
  })
}
</script>
<style lang="less">
.liuliuTip{
  flex-direction: row-reverse;
  img{
    width: 210px;
    height: 24px;
  }
}
</style>
<style lang="less" scoped>
.top {
  box-sizing: border-box;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .title {
    max-width: 18.25rem;
  }
}
.fileBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 100px;
  grid-gap: 5px;
}
.imgOne {
  width: 132px;
  height: 200px;
  border-radius: 6px;
}

.fileBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.comments{
  margin-top: 24px;
}

.con{
  line-height: 24px;
  margin-bottom: 12px;
  .nickName{
    // display: inline-block;
    // max-width:100px;
    // min-width: 50px;
    color: #505D80;
  }
}
.noneImg{
  padding-top: 120px;
  text-align: center;
  img{
    width: 130px;
  }
}
video{
  width: 200px;
  height: 300px;
}
.partyInfo{
  width: 288px;
  height: 147px;
  border-radius: 6px;
}
.picText{
  width: 288px;
  height: 32px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px 0px 6px 6px;
  left: 0;
  bottom: 5px;
  padding-left: 12px;
}
</style>
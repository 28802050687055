import { post } from '@/utils/http'
// 苹果树页面数据
export const getTree = function (data) {
    return post({
        url: "/app/apple/getAppleTree",
        data
    })
}
export const getHistoryAppleTree = function (data) {
    return post({
        url: "/app/apple/getHistoryAppleTree",
        data
    })
}
export const getShowAppleTree = function (data) {
    return post({
        url: "/app/apple/getShowAppleTree",
        data
    })
}
export const updateShowAppleTree = function (data) {
    return post({
        url: "/app/apple/updateShowAppleTree",
        data
    })
}

<template>
    <div class="bg re_sizing">
        <div class="title re_color_white re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>赚钱攻略</span>
        </div>
        <img class="re_margin_top_24 intro_img1" src="../../../assets/invite/01.png" alt="">
        <img class="re_margin_top_24 intro_img2" src="../../../assets/invite/02.png" alt="">
        <img class="re_margin_top_24 intro_img3" src="../../../assets/invite/03.png" alt="">
        <img class="re_margin_top_24 intro_img4" src="../../../assets/invite/04.png" alt="">

    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const goBack = () => {
    router.go(-1)
}
</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 1631px;
    background: url('../../../assets/invite/introbg.png') no-repeat center center;
    background-size: 100% 100%;
    padding-top: 50px;
    text-align: center;
    img{
        width: 343px;
    }
    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .intro_img1{
        height: 350px;
    }
    .intro_img2{
        height: 372px;
    }
    .intro_img3{
        height: 440px;
    }
    .intro_img4{
        height: 282px;
    }

}
</style>
import { post } from '@/utils/http'
// 抽奖页信息
export const getInfo = function (data) {
    return post({
        url: "/app/business/activit/account/getInfo",
        data
    })
}

// 最早要到期的一笔的金额提醒
export const expireWarn = function (data) {
  return post({
      url: "/app/business/activit/record/expireWarn",
      data
  })
}

//获取活动设置-规则
export const ruleInfo = function (data) {
    return post({
        url: "/app/business/activit/config/getInfo",
        data
    })
  }

//明细-抽奖记录
export const list = function (data) {
    return post({
        url: "/app/business/activit/record/list",
        data
    })
  }

  //明细-提现记录
export const getDrawList = function (data) {
    return post({
        url: "/app/draw/getDrawList",
        data
    })
  }
  //提现
export const applyDraw = function (data) {
    return post({
        url: "/app/draw/applyDraw",
        data
    })
  }


<template>
    <div class="bg re_sizing" :style="showMask ? 'position: fixed' : 'position:static'">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>我的奖金</span>
        </div>
        <div class="content re_sizing re_flex_between">
            <div class="item re_flex_center">
                <div>
                    <div class="re_font_14 re_color_light">已获得</div>
                    <div class="re_relative">
                        <span class="re_font_12">￥</span>
                        <span class="re_font_bold money re_color_second">{{infoData.obtainAmount}}</span>
                    </div>
                </div>
            </div>
            <div class="item re_flex_center">
                <div>
                    <div class="re_font_14 re_color_light">待提现</div>
                    <div class="re_relative">
                        <span class="re_font_12">￥</span>
                        <span class="re_font_bold money re_color_second">{{infoData.withdrawingAmount}}</span>
                    </div>
                </div>
            </div>
            <div @click="getMoney" class="btn re_flex_center re_font_14 re_color_white">提现</div>
        </div>
        <div class="line"></div>
        <div class="scroll re_sizing">
            <div class="tag"><span class="re_relative re_margin_l_8">提现记录</span></div>
            <div class="tag" style="border-bottom: none;" v-if="listData.length > 0">
                <div v-for="item in listData" class="item re_flex_between re_margin_bot_8">
                    <div class="item_left">
                        <div class="re_font_14 re_color_second re_font_bold">提现至支付宝</div>
                        <div class="re_font_14 re_margin_top_8 re_color_light">{{item.createTime}}</div>
                    </div>
                    <div class="item_right">
                        <div class="re_font_bold">
                            <span class="re_font_10">￥</span>
                            <span class="re_font_14">{{item.money}}</span>
                        </div>
                        <div class="re_font_10" style="color:#FE8A51">{{item.auditStatusStr}}</div>
                    </div>
                </div>
            </div>
            <p v-else class="re_font_14 re_flex_center re_padingtb64 re_color_second">暂无数据</p>
        </div>
    </div>
</template>

<script setup>

import { getDrawList, applyDraw, getIncome,checkSubject  } from './api.js';
import { ref, onMounted } from 'vue';
import { showDialog, showConfirmDialog } from 'vant';

const infoData = ref({});
import { useRouter } from 'vue-router'
const router = useRouter()
onMounted(() => {
    getList(1)
    getInitMoney()
})
const goBack = () => {
    router.go(-1)
}
const isAli = ref(true)
const getInitMoney = () => {
    getIncome().then(res => {
        infoData.value = res.data;
    })
}
const listData = ref([]);
const getList = () => {
    let params = {};
    params.rulesType = 6,
    params.pageSize = 999
    getDrawList(params).then(res => {
        listData.value = res.data;
    })
}
const getMoney = async () => {
    if(infoData.value.withdrawingAmount == 0) {
        showDialog({ title: '提示', message: '暂无提现金额' });
        return;
    }
    await checkSubject().then(res => {
        isAli.value = res.data.isAli == 1 ? true : false;
    })
    if(!isAli.value){
        showConfirmDialog({
            title: '提示',
            message: '请先完成支付宝绑定后进行提现',
            confirmButtonText: '去绑定'
        }).then(res => {
            if (res === 'confirm') {
                let u = navigator.userAgent;
                let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
                let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid) {
                    appJsImp.toBindAlipay()   // 绑定支付宝
                }
                if (isiOS) {
                    window.webkit.messageHandlers.AppModel.postMessage('toBindAlipay');
                }
            }
        })  
    }else{
        showConfirmDialog({
            title: '提示',
            message: '是否提交提现申请？',
        }).then(res => {
            if (res === 'confirm') {
                getMyMoney()
            }
        })
    }
}
const getMyMoney = () => {
    applyDraw({
        rulesType: 6,
        money: infoData.value.withdrawingAmount,
        withdrawType: 1
    }).then(res => {
        showDialog({ title: '提示', message: res.msg });
        getInitMoney()
        getList()
    })
}
</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    padding-top: 50px;

    .title {
        width: 100%;
        font-weight: 600;
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .content {
        width: 100%;
        padding: 40px 26px 26px 18px;
        .item {
            width: 30%;
            text-align: center;
            .money {
                font-size: 24px;
                top: 12px;
            }
            .re_relative{
                top: 8px;
            }

            .sign {
                color: #B3B4BA;
            }
        }

        .btn {
            width: 40%;
            width: 80px;
            height: 32px;
            background: #FE8A51;
            border-radius: 4px;
            margin-right: 0;
        }
    }
    .line{
        width: 100%;
        height: 8px;
        background-color: #F5F7F9;
    }
    .scroll {
        width: 96%;
        margin: 0 auto;
        min-height: 260px;
        
        .tag {
            border-bottom: 1px solid #F5F7F9;
            padding: 10px 0;
        }
        .re_font_12 {
            margin-top: 4px;
        }

        .item {
            height: 50px;
            padding: 0 12px 0px 12px;

        }

        .item_right {
            color: #FA3128;
            text-align: right;
            .tag2{
                color: #FC5C63;
            }
            .tag3{
                color: #FE8A51;
            }
        }
    } 
}
</style>
<template>
    <div class="bgBox re_sizing" :style="(showMask || showReal || showRule) ? 'position: fixed' : 'position:static'">
        <div class="bgIndex re_sizing">
            <div class="title re_relative re_flex_center re_font_18">
                <van-icon @click="goBack(1)" class="icon re_color_white" name="arrow-left" size="22" />
            </div>
            <img class="rule1 re_absolute" @click="goRule(1)" src="../../../assets/invite/rule.png" alt="">
            <img class="rule2 re_absolute" @click="goMoney" src="../../../assets/invite/rule2.png" alt="">
            <div class="btn re_absolute" @click="showCodeMask"></div>
            <div class="re_flex re_absolute shareBtn">
                <div @click="goBack(3)"></div>
                <div @click="goBack(9)"></div>
                <div @click="goBack(4)"></div>
            </div>
        </div>
        <div class="my re_paddinglr_16 re_sizing">
            <div class="re_flex_between">
                <span class="re_font_14 re_font_bold">我的收益</span>
                <img class="go" @click="goRule(2)" src="../../../assets/invite/btn2.png" alt="">
            </div>
            <div class="re_flex re_margin_top_8 money">
                <template v-if="!isReal">
                    <div class="goReal" @click="goBack(2)">
                        <img src="../../../assets/invite/goReal.png" alt="">
                    </div>
                </template>
                <template v-else>
                    <div class="item">
                        <div class="re_font_12 box">已获得</div>
                        <div class="re_margin_top_4">
                            <span class="re_font_14">￥</span>
                            <span class="re_font_20">{{ infoData.obtainAmount }}</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="re_font_12 box">待提现</div>
                        <div class="re_margin_top_4">
                            <span class="re_font_14">￥</span>
                            <span class="re_font_20">{{ infoData.withdrawingAmount }}</span>
                        </div>
                    </div>
                    <div class="item success re_relative">
                        <div class="re_font_12 box re_relative">
                            <span class="re_font_12">成功邀请</span>
                            <img class="tip re_absolute" v-show="tipShow" src="../../../assets/invite/tip.png" alt="">
                            <img @click="tipShow = !tipShow" class="question re_relative"
                                src="../../../assets/invite/question.png" alt="">
                        </div>
                        <div class="re_margin_top_4">
                            <span class="re_font_20">{{ infoData.inviteNum }}</span>
                            <span class="re_font_14">人</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="!earnIdentity" class="charge" @click="showRecharge"></div>
        <div v-else class="btnYi" @click="showReal = true"></div>
        <div class="scroll re_sizing re_relative">
            <div class="scroll_title re_flex">
                <div class="tag1" @click="changeTab(1)">
                    <img v-if="titleIndex == 1" class="tag1_img" src="../../../assets/invite/tag1.png" alt="">
                    <img v-else class="tag2_img" src="../../../assets/invite/tag2.png" alt="">
                </div>
                <div class="tag2" @click="changeTab(2)">
                    <img v-if="titleIndex == 1" class="tag2_img" src="../../../assets/invite/tag3.png" alt="">
                    <img v-else class="tag1_img" src="../../../assets/invite/tag4.png" alt="">
                </div>
            </div>
            <div class="scroll_content re_margin_top_8">
                <template v-if="isReal">
                    <van-list offset="30" :immediate-check="false" v-model:loading="loading" :finished="finished"
                        @load="onLoad" finished-text="没有更多了">
                        <van-cell v-for="item in listData" :key="item"
                            class="item re_flex_between re_font_bold re_margin_top_24" :title="item">
                            <div v-if="titleIndex == 1" class="item_left">
                                <div class="re_font_16 re_color_normal">{{item.label}}</div>
                                <div class="re_font_14 re_color_second re_margin_top_4">来源用户：{{ item.registerNickName }}
                                </div>
                                <div class="re_font_12 re_color_light re_margin_top_4">{{ item.createTime }}</div>
                            </div>
                            <div v-else class="item_left re_flex">
                                <img class="avatar" :src="item.photo" alt="">
                                <div class="re_margin_l_8">
                                    <div class="re_flex">
                                        <span class="re_font_16">{{ item.nickname }}</span>
                                        <span v-if="item.certified != 1"
                                            class="real re_relative re_flex_center re_font_10 color_red re_margin_l_8">待实名</span>
                                    </div>
                                    <div class="re_font_12 re_color_light re_margin_top_4">{{ item.createTime }}</div>
                                </div>

                            </div>
                            <div v-if="titleIndex == 1" class="item_right color_red">
                                <div>
                                    <span class="re_font_14">￥</span>
                                    <span class="re_font_20">{{ item.userAmount }}</span>
                                </div>
                            </div>
                        </van-cell>
                    </van-list>
                </template>
                <img v-else class="realIcon" src="../../../assets/invite/real.png" alt="">

            </div>
        </div>
        <div v-show="showMask" class="mask re_absolute re_sizing re_flex_center">
            <div class="re_relative">
                <img @click="goBack(8)" class="share re_absolute" src="../../../assets/invite/share.png" alt="">
                <div class="ruleBg re_sizing">
                    <div @mousedown="startPress" @mouseup="cancelPress" @mouseleave="cancelPress"
                        @touchstart="startPress" @touchend="cancelPress" class="rule re_flex_center re_sizing">
                        <img v-if="inviteQrcode" class="inviteQrcode" :src="inviteQrcode" alt="">
                        <img class="re_absolute photo" :src="photo" alt="">
                    </div>
                </div>
                <div @click="goBack(6)" class="saveImg re_absolute"></div>
                <img @click="showMask = false" class="re_relative close"
                    src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
            </div>
        </div>
        <div v-show="showShare" class="mask2 mask re_absolute re_sizing re_flex_center">
            <!-- <div class="re_relative"> -->
                <img @click.stop="showShare = false" class="re_absolute closeShare" src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
                <div @click="goBack(6)" class="tangBg re_absolute re_sizing">
                    <!-- <div @mousedown="startPress" @mouseup="cancelPress" @mouseleave="cancelPress"
                        @touchstart="startPress" @touchend="cancelPress" class="rule re_flex_center re_sizing re_absolute"> -->
                        <img v-if="inviteQrcode" class="inviteQrcode re_absolute" :src="inviteQrcode" alt="">
                    <!-- </div> -->
                </div>
            <!-- </div> -->
        </div>
        <div v-show="showReal" class="mask re_absolute re_sizing re_flex_center">
            <div class="bottom">
                <img class="close re_absolute" @click="showReal = false"
                    src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
                <img v-if="!earnIdentity" @click="goBack(5)" class="disabled re_absolute"
                    src="../../../assets/invite/open.png" alt="">
                <img v-else class="disabled re_absolute" src="../../../assets/invite/disabled.png" alt="">
            </div>
        </div>
        <div v-show="showRule" class="mask re_absolute re_sizing re_flex_center">
            <div>
                <div class="ruleMask re_font_14 re_color_third re_sizing">
                    <div class="ruleBox" v-html="ruleInfo"></div>
                </div>
                <div @click="showRule = false" class="re_relative closeRule"></div>
            </div>
        </div>
    </div>
</template>

<script setup>

// import { is } from 'core-js/core/object';
import { checkSubject, userInviteShareWxInfo, inviteList, recordList, getIncome, getInfo } from './api.js';
import { ref, onMounted } from 'vue';

const titleIndex = ref(1);
const showMask = ref(false); // 二维码弹窗
const tipShow = ref(false); // 成功邀请小tip
const showReal = ref(false); // 达人身份弹窗
const isReal = ref(false); // 是否实名认证弹窗
const inviteQrcode = ref('');
const photo = ref('');
const earnIdentity = ref(false); // 是否达人身份
const infoData = ref({});
const listData = ref([]);
const showRule = ref(false)
const ruleInfo = ref('')
const showShare = ref(false)
import { useRouter } from 'vue-router'
const router = useRouter()
const pressTimer = ref(null)
const startPress = () => {
    if (pressTimer.value === null) {
        pressTimer.value = setTimeout(() => {
            handleLongPress();
        }, 1000); // 长按时间阈值，单位是毫秒
    }
}


const cancelPress = () => {
    if (pressTimer.value !== null) {
        clearTimeout(pressTimer.value);
        pressTimer.value = null;
    }
}
const showRecharge = () => {
    userInviteShareWxInfo().then(res => {
        earnIdentity.value = res.data.earnIdentity;
        showReal.value = true
    })
}
const handleLongPress = () => {
    goBack(6)
}
var surl = window.location.href;

var obj = {};
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
 obj[temp[0]] = temp[1];
}
onMounted(() => {
    getInit()
    getInfo().then(res => {
        ruleInfo.value = res.data.ruleContent;
    })
    window.getInit = function () {
        getInit()
    };
    if (!sessionStorage.getItem('showRule')) {
        showRule.value = true
        sessionStorage.setItem('showRule', true)
    }

})
const getInit = () => {
    checkSubject().then(res => {
        isReal.value = res.data.isSubject == 1 ? true : false;
    })
    userInviteShareWxInfo().then(res => {
        inviteQrcode.value = res.data.inviteQrcode;
        // photo.value = res.data.photo;
        earnIdentity.value = res.data.earnIdentity;
    })
    getIncome().then(res => {
        infoData.value = res.data;
    })
    onLoad(1)
}
const showCodeMask = () => {
    if (isReal.value) {
        showMask.value = true
    } else {
        // 去实名认证
        goBack(7)
    }
}
const goRule = (type) => {
    if (type == 1) showRule.value = true
    if (type == 2) {
        let url = null
        if(process.env.VUE_APP_API_URL === 'https://llcb.kaishuihu.com/dsgc'){
            url = 'https://www.kaishuihu.com/newYear/index.html#/red/my/money?user_id='+obj.user_id+'&token='+obj.token
        }else{
            url = 'https://demo.lanlingcb.com/newYear/index.html#/red/my/money?user_id='+obj.user_id+'&token='+obj.token
        }
        window.location.href = url
    }
}

const goBack = (type) => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (type == 9) {
    //     showShare.value = true
    //     return
    // }
    if (isAndroid) {
        if (type == 1) appJsImp.goBack()   // 返回
        if (type == 2) appJsImp.toBindAlipay()   // 绑定支付宝
        if (type == 3) appJsImp.toShare()   // 分享
        if (type == 4) appJsImp.toShareCircle()   // 分享
        if (type == 5) appJsImp.toRechargeLLb()   // 打开达人身份
        if (type == 6) appJsImp.saveImg()   // 打开达人身份
        if (type == 7) appJsImp.toCertification()   // 去实名认证
        if (type == 8) appJsImp.shareAllWechat()   // 去实名认证
        if (type == 9) appJsImp.toShareImagePage()   // 去实名认证

    }
    if (isiOS) {
        if (type == 1) window.webkit.messageHandlers.AppModel.postMessage('goBack');
        if (type == 2) window.webkit.messageHandlers.AppModel.postMessage('toBindAlipay');
        if (type == 3) window.webkit.messageHandlers.AppModel.postMessage('toShare');
        if (type == 4) window.webkit.messageHandlers.AppModel.postMessage('toShareCircle');
        if (type == 5) window.webkit.messageHandlers.AppModel.postMessage('toRechargeLLb');
        if (type == 6) window.webkit.messageHandlers.AppModel.postMessage('saveImg');
        if (type == 7) window.webkit.messageHandlers.AppModel.postMessage('toCertification');
        if (type == 8) window.webkit.messageHandlers.AppModel.postMessage('shareAllWechat');
        if (type == 9) window.webkit.messageHandlers.AppModel.postMessage('toShareImagePage');
    }
}
const loading = ref(false);
const finished = ref(false);
const pageParams = ref({
    pageNum: 1,
    pageSize: 10
});
const changeTab = (index) => {
    pageParams.value.pageNum = 1;
    listData.value = [];
    titleIndex.value = index;
    finished.value = false
    getList()

}
const onLoad = (page) => {
    if (page == 1) {
        pageParams.value.pageNum == 1
    } else {
        pageParams.value.pageNum += 1;
    }
    getList(titleIndex.value)
}
const getList = () => {
    loading.value = true;
    let url = titleIndex.value == 1 ? recordList : inviteList;
    url(pageParams.value).then(res => {
        if(res.code == 500) finished.value=true
        loading.value = false;
        listData.value = pageParams.value.pageNum === 1 ? res.rows : listData.value.concat(res.rows);
        if (res.rows.length < pageParams.value.pageSize) {
            finished.value = true;
        }
    })
}
const goMoney = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setTimeout(() => {
        router.push('/actInvite/intro')
    }, 300);
}

</script>

<style lang="less">
.ruleBox {
    .titleRed {
        font-size: 16px !important;
        font-weight: 700 !important;
        margin: 16px 0 8px 0 !important;
        color: #FF7537 !important;
    }
}
</style>
<style lang="less" scoped>
@import './index.less';
</style>
<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18">
            <!-- <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" /> -->
        </div>
        <img @click="showData = true" class="appleTreeSet re_absolute" src="../../../assets/appleTree/apple_tree_set_icon.png">
        <img class="rulesBtn re_absolute" @click="showRules = true"
            src="https://www.kaishuihu.com/h5/appleTree/img/applebtn.png">
        <div class="cloud re_absolute"></div>
        <!-- <div class="light"></div> -->
        <div class="treeBox re_absolute">
            <img v-if="tree.state" :class="tree.state == '2' ? 'tree re_absolute' : 'noTree re_absol    ute'"
                :src="require(`../../../assets/appleTree/apple${tree.state}.png`)" alt="">
            <div class="apple re_sizing re_absolute">
                <template v-for="item in tree.tree" :key="item">
                    <img v-if="item > 2" :src="require(`../../../assets/appleTree/apple${item}.png`)"
                        alt="" />
                </template>
            </div>
        </div>
        <div @click="goMedal" class="re_absolute mymedal">
            <img src="../../../assets/appleTree/mymedal.png" alt="">
        </div>
        <!-- <div class="shadow" >
        </div> -->
        <div class="progress-box re_absolute">
            <div class="register re_relative re_flex_center re_font_20">
                {{ tree.medalLevel || 'lv.0' }}
            </div>
            <div class="progress-slot re_absolute">
                <div class="progress-txt re_color_white re_absolute re_font_14">{{ progress }}%</div>
                <div class="progress" :style="`width:${progress}%`"></div>
            </div>
        </div>
        <div v-if="tree.nextGrowthValueDiff" class="bubble re_font_14 re_absolute">
            <span class="bot2 re_relative"></span>
            {{tree.nextGrowthValueDiff}}
             <!-- 当回事化生地回复的搜发货偶读 -->
        </div>
        <div v-show="showRules" class="imgBox re_absolute">
            <img src="../../../assets/appleTree/appleRules.png" alt="">
            <div class="closeBtn" @click="showRules = false"></div>
        </div>
        <van-popup round v-model:show="showData" position="bottom" >
            <div class="setting">
                <div class="re_flex_center title re_relative">
                    <span class="re_color_white re_font_16">果园设置</span>
                    <img @click="showData = false" class="re_absolute" src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
                </div>
                <div class="re_sizing look re_padinglr24 re_flex_between ">
                    <span class="re_font_16 re_font_bold re_color_second">其他人可查看我的果园</span>
                    <img v-if="closeFlag" @click="changeStatus" class="re_relative" src="../../../assets/appleTree/close.png" alt="">
                    <img v-else @click="changeStatus" class="re_relative" src="../../../assets/appleTree/open.png" alt="">

                </div>
            </div>
        </van-popup>
    </div>
</template>

<script setup>
import { router } from '../../router';
import { getTree, appleTreeVisit, getShowAppleTree, getHistoryAppleTree } from './api'
import { ref, onMounted } from 'vue';

const showRules = ref(false)
const showData = ref(false)
const closeFlag = ref(false)
const tree = ref({})
const progress = ref(0)
const getInit = () => {
    getTree().then(res => {
        if (res.code == 1) {
            res.data.state = res.data.state > 2 ? '2' : res.data.state.toString()
            // 还没长成树
            tree.value = res.data
            // 进度条
            progress.value = Math.floor(res.data.nowGrowthValue / (res.data.nextGrowthValue / 100));
            console.log(tree.value)
        }
    })
    // getShowAppleTree({
    //     row: 8
    // }).then(res => {
    //     console.log(res)
    // })
}
getInit()
const goMedal = () => {
    router.push({
        path: '/appleTree/medal',
    })
}
const changeStatus = () =>{
    appleTreeVisit({
        row: 8
    }).then(res=>{
        if(res.code === 1){
            closeFlag.value = !closeFlag.value
        }
    })

}
</script>

<style lang="less" scoped>
@import './index.less';

.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/appleTree/bg.jpg') left top no-repeat;
    background-size: 100% 100%;

    .title {
        width: 100%;
        font-weight: 600;
        z-index: 9;

        span {
            left: 10px;
        }

        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
}
</style>
<template>
    <div class="bg re_sizing">
        <div class="title re_color_white re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span class="re_relative">邀请分享</span>
            <img @click="goBack(2)" class="iconBox re_relative" src="../../../assets/invite/shareIcon.png" alt="">
        </div>
        <div class="code re_relative re_sizing re_padingtb24">
            <div @click="goBack(3)" class=" codeText re_absolute"></div>
            <img v-if="inviteQrcode" class="codeImg re_margin_top_16" :src="inviteQrcode" alt="">
            <div class="codeBtn re_flex_center re_font_12 re_color_second">
                <div @click="goOther(1)" class="btnBox">
                    <img src="../../../assets/invite/s01.png" alt="">
                    <div>活动详情</div>
                </div>
                <div @click="goOther(2)" class="btnBox">
                    <img src="../../../assets/invite/s02.png" alt="">
                    <div>收益明细</div>
                </div>
                <div @click="goOther(3)" class="btnBox">
                    <img src="../../../assets/invite/s03.png" alt="">
                    <div>赚钱攻略</div>
                </div>
                <div @click="goOther(4)" class="btnBox">
                    <img src="../../../assets/invite/s04.png" alt="">
                    <div>推广规则</div>
                </div>
            </div>
        </div>
        <div v-if="!earnIdentity" class="charge" @click="showReal = true"></div>
        <div v-else class="btnYi" @click="showReal = true"></div>
        <div v-show="showReal" class="mask re_absolute re_sizing re_flex_center">
            <div class="bottom">
                <img class="close re_absolute" @click="showReal = false"
                    src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
                <img v-if="!earnIdentity" @click="goBack(5)" class="disabled re_absolute" src="../../../assets/invite/open.png" alt="">
                <img v-else class="disabled re_absolute" src="../../../assets/invite/disabled.png" alt="">

            </div>
        </div>
        <div v-show="showRule" class="mask re_absolute re_sizing re_flex_center">
            <div>
                <div class="ruleMask re_sizing">
                    <div class="ruleBox" v-html="ruleInfo"></div>
                </div>
                <div @click="showRule = false" class="re_relative closeRule"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { userInviteShareWxInfo, getInfo } from './api.js'
import { ref, onMounted } from 'vue';
const router = useRouter()
const showReal = ref(false);// 达人身份弹窗
const earnIdentity = ref(false); // 是否达人身份
const inviteQrcode = ref('');
const ruleInfo = ref('')
const showRule = ref(false)
const goBack = (type) => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        if (type == 1) appJsImp.finish()   // 返回
        if (type == 2) appJsImp.shareAllWechat()   // 分享
        if (type == 3) appJsImp.saveImg()   // 绑定支付宝
        if (type == 5) appJsImp.toRechargeLLb()   // 打开达人身份
    }
    if (isiOS) {
        if (type == 1) window.webkit.messageHandlers.AppModel.postMessage('finish');
        if (type == 2) window.webkit.messageHandlers.AppModel.postMessage('shareAllWechat');
        if (type == 3) window.webkit.messageHandlers.AppModel.postMessage('saveImg');
        if (type == 5) window.webkit.messageHandlers.AppModel.postMessage('toRechargeLLb');
    }
}
onMounted(() => {
    window.getInit = function () {
        userInviteShareWxInfo().then(res => {
            inviteQrcode.value = res.data.inviteQrcode;
            earnIdentity.value = res.data.earnIdentity;
        })
    };
    userInviteShareWxInfo().then(res => {
        inviteQrcode.value = res.data.inviteQrcode;
        earnIdentity.value = res.data.earnIdentity;
    })
    getInfo().then(res => {
        ruleInfo.value = res.data.ruleContent;
    })
})
const goOther = (type) => {
    if(type == 1){
        router.push('/actInvite')
    }else if(type == 2){
        router.push('/actInvite/income')
    }else if(type == 3){
        router.push('/actInvite/intro')
    }else if(type == 4){
        // router.push('/actInvite/rule')
        showRule.value = true
    }
}

</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/invite/shareBg.png') left top no-repeat;
    background-size: 100% 100%;
    .title {
        width: 100%;
        font-weight: 600;
        span{
            left: 10px;
        }
        .iconBox{
            top: 4px;
            left: 32%;
            width: 24px;
            height: 24px;
        }
        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
    .code{
        width: 343px;
        height: 490px;
        background: url('../../../assets/invite/codeBg.png') left top no-repeat;
        background-size: 100% 100%;
        margin: 40px auto;
        text-align: center;
        .codeImg{
            width: 260px;
            height: 260px;
        }
        .codeBtn{
            margin-top: 110px;
            .btnBox{
                width: 25%;
                text-align: center;
            }
            img{
                width: 36px;
                height: 36px;
            }
        }
    }
    .charge{
        position: fixed;
        bottom: 25px;
        left: 15px;
        width: 343px;
        height: 54px;
        background: url('../../../assets/invite/shareBtn.png') left top no-repeat;
        background-size: 100% 54px;
    }
    .btnYi {
        position: fixed;
        bottom: 25px;
        left: 15px;
        width: 343px;
        height: 54px;
        background: url('../../../assets/invite/btnYi.png') left top no-repeat;
        background-size: 100% 54px;
        
    }
}
.mask {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    text-align: center;
    position: fixed;
    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 340px;
        background: url('../../../assets/invite/intro.png') no-repeat center center;
        background-size: 100% 100%;

        .close {
            top: 20px;
            right: 20px;
            width: 26px;
            height: 26px;
        }

        .disabled {
            width: 344px;
            height: 48px;
            bottom: 40px;
            left: 15px;
        }
    }
}
.codeText{
    width: 100%;
    height: 30px;
    top: 10px;
}
.mask {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    text-align: center;
    position: fixed;
    .ruleMask {
        width: 323px;
        height: 493px;
        background: url('../../../assets/invite/ruleMask.png') no-repeat center center;
        background-size: 100% 100%;
        // padding-top: 60px;
        padding: 60px 30px 0 30px;
        text-align: left;
        overflow-y: scroll;
    }
    .ruleBox{
        height: 340px;
        overflow-y: scroll;
    }
    .closeRule{
        width: 32px;
        height: 32px;
        left: 50%;
        margin-left: -16px;
        bottom: 32px;
    }
}
</style>
<template>
    <div class="bg re_min_page re_sizing">
        <div class="title re_bg_white re_relative re_flex_center re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>收益明细</span>
        </div>
        <div style="margin-top: 12px;" class="my re_paddinglr_16 re_sizing">
            <div class="re_flex_between">
                <span class="re_font_14 re_font_bold">我的收益</span>
                <img class="go" @click="goRule()" src="../../../assets/invite/btn2.png" alt="">
            </div>
            <div class="re_flex re_margin_top_8 money">
                <template v-if="!isReal">
                    <div class="goReal" @click="goBack(2)">
                        <img src="../../../assets/invite/goReal.png" alt="">
                    </div>
                </template>
                <template v-else>
                    <div class="item">
                        <div class="re_font_12 box">已获得</div>
                        <div class="re_margin_top_4">
                            <span class="re_font_14">￥</span>
                            <span class="re_font_20">{{ infoData.obtainAmount }}</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="re_font_12 box">待提现</div>
                        <div class="re_margin_top_4">
                            <span class="re_font_14">￥</span>
                            <span class="re_font_20">{{ infoData.withdrawingAmount }}</span>
                        </div>
                    </div>
                    <div class="item success re_relative">
                        <div class="re_font_12 box re_relative">
                            <span class="re_font_12">成功邀请</span>
                            <img class="tip re_absolute" v-show="tipShow" src="../../../assets/invite/tip.png" alt="">
                            <img @click="tipShow = !tipShow" class="question re_relative"
                                src="../../../assets/invite/question.png" alt="">
                        </div>
                        <div class="re_margin_top_4">
                            <span class="re_font_20">{{ infoData.inviteNum }}</span>
                            <span class="re_font_14">人</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div style="margin-top: 12px;height:68vh" class="scroll re_sizing re_relative">
            <div class="scroll_title re_flex">
                <div class="tag1" @click="changeTab(1)">
                    <img v-if="titleIndex == 1" class="tag1_img" src="../../../assets/invite/tag1.png" alt="">
                    <img v-else class="tag2_img" src="../../../assets/invite/tag2.png" alt="">
                </div>
                <div class="tag2" @click="changeTab(2)">
                    <img v-if="titleIndex == 1" class="tag2_img" src="../../../assets/invite/tag3.png" alt="">
                    <img v-else class="tag1_img" src="../../../assets/invite/tag4.png" alt="">
                </div>
            </div>
            <div class="scroll_content re_margin_top_8" style="height:60vh;">
                <template v-if="isReal">
                    <van-list offset="5" v-model:loading="loading" :finished="finished" @load="onLoad" finished-text="没有更多了">
                        <van-cell v-for="item in listData" :key="item" class="item re_flex_between re_font_bold re_margin_top_24"
                            :title="item">
                            <div v-if="titleIndex == 1" class="item_left">
                                <div class="re_font_16 re_color_normal">{{item.label}}</div>
                                <div class="re_font_14 re_color_second re_margin_top_4">来源用户：{{ item.registerNickName}}</div>
                                <div class="re_font_12 re_color_light re_margin_top_4">{{ item.createTime }}</div>
                            </div>
                            <div v-else class="item_left re_flex">
                                <img class="avatar" :src="item.photo" alt="">
                                <div class="re_margin_l_8">
                                    <div class="re_flex">
                                        <span class="re_font_16">{{ item.nickname }}</span>
                                        <span v-if="item.certified != 1"
                                            class="real re_relative re_flex_center re_font_10 color_red re_margin_l_8">待实名</span>
                                    </div>
                                    <div class="re_font_12 re_color_light re_margin_top_4">{{ item.createTime }}</div>
                                </div>

                            </div>
                            <div v-if="titleIndex == 1" class="item_right color_red">
                                <div>
                                    <span class="re_font_14">￥</span>
                                    <span class="re_font_20">{{ item.userAmount }}</span>
                                </div>
                            </div>
                        </van-cell>
                    </van-list>
                </template>
                <img v-else class="realIcon" src="../../../assets/invite/real.png" alt="">

            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { getIncome, checkSubject, inviteList, recordList } from './api.js'
import { ref, onMounted } from 'vue';
const infoData = ref({});
const isReal = ref(false); // 是否实名认证弹窗
const listData = ref([]);
const loading = ref(false);
const finished = ref(false);
const titleIndex = ref(1);
const tipShow = ref(false); // 成功邀请小tip

const pageParams = ref({
    pageNum: 1,
    pageSize: 10
});
const onLoad = (page) => {
    if(page == 1){
        pageParams.value.pageNum == 1
    }else{
        pageParams.value.pageNum += 1;
    }
    getList(titleIndex.value)
}
const goRule = () => {
    router.push({
        path: '/actInvite/prize',
        query: {
            obtainAmount: infoData.value.obtainAmount,
            withdrawingAmount: infoData.value.withdrawingAmount
        }
    })
}
const getList = () => {
    loading.value = true;
    let url = titleIndex.value == 1 ? recordList : inviteList;
    url(pageParams.value).then(res => {
        loading.value = false;
        listData.value = pageParams.value.pageNum === 1 ? res.rows : listData.value.concat(res.rows);
        if (res.rows.length < pageParams.value.pageSize) {
            finished.value = true;
        }
    })
}
const changeTab = (index) => {
    pageParams.value.pageNum = 1;
    listData.value = [];
    titleIndex.value = index;
    finished.value = false
    getList()

}
const router = useRouter()
const goBack = (type) => {
    if(type == 1){
        // router.go(-1)
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            appJsImp.goBack()   // 返回
        }
        if (isiOS) {
            window.webkit.messageHandlers.AppModel.postMessage('goBack');
        }
    }else if(type == 2){
        router.push({
            path: '/actInvite/prize',
            query: {
                obtainAmount: infoData.value.obtainAmount,
                withdrawingAmount: infoData.value.withdrawingAmount
            }
        })
    }
}
onMounted(() => {
    getIncome().then(res => {
        infoData.value = res.data;
    })
    checkSubject().then(res => {
        isReal.value = res.data.isSubject == 1 ? true : false;
    })
    onLoad(1)

})
</script>

<style lang="less" scoped>
@import './index.less';

</style>
<template>
    <div class="re_min_page re_sizing re_paddingtb_24 re_paddinglr_16">
      <div class="noneImg">
        <img src="../../../assets/appleTree/appleRulesLock.png" alt="">
        <p class="re_color_second">朋友设置了访问权限，暂无法查看TA的果园</p>
      </div>
  
      
    </div>
  </template>
  
  <script setup>
  
 
  </script>
  <style lang="less">
  .liuliuTip{
    flex-direction: row-reverse;
    img{
      width: 210px;
      height: 24px;
    }
  }
  </style>
  <style lang="less" scoped>
  .noneImg{
    text-align: center;
    padding-top: 160px;
    img{
      width: 168px;
      height: 168px;
    }
  }
  </style>
<template>
    <div :class="isWechat ? 'bg bgWechat' : 'bg bgnormal'" :style="showMask ? 'position: fixed' : 'position:static'">
        <div v-if="!isWechat" class="title re_relative re_flex_center re_color_white re_font_18">
            <van-icon @click="goBack(1)" class="icon" name="arrow-left" size="22" />
            <span>签到活动</span>
        </div>
        <div :class="isWechat ? 'shareBtn commonW re_absolute' : 'shareBtn commonP re_absolute'" @click="shareWechat">
        </div>
        <div :class="isWechat ? 'ruleBtn commonW re_absolute' : 'ruleBtn commonP re_absolute'" @click="showMask = true">
        </div>
        <img :class="isWechat ? 'share commonW re_absolute' : 'share commonP re_absolute'"
            src="../../../assets/activity/share.png" alt="">
        <div class="content re_sizing" :style="isWechat ? 'margin-top: 120px' : 'margin-top: 156px'">
            <div class="re_flex">
                <div class="item re_flex_center">
                    <div>
                        <div class="re_font_bold">
                            <span class="re_font_18">￥</span>
                            <span :class="infoData.obtainAmount * 1 > 1000 ? 're_font_22' : 'money'">{{
                                infoData.obtainAmount }}</span>
                        </div>
                        <div class="re_font_14">已获得</div>
                    </div>
                </div>
                <div class="item re_flex_center">
                    <div>
                        <div class="re_font_bold">
                            <span class="re_font_18">￥</span>
                            <span :class="infoData.withdrawingAmount * 1 > 1000 ? 're_font_22' : 'money'">{{
                                infoData.withdrawingAmount }}</span>
                        </div>
                        <div class="re_font_14">可提现</div>
                    </div>
                </div>
            </div>
            <div class="re_font_14 re_color_white tips">{{ expireWarnData.expireWarn }}</div>
            <div class="submit" @click="getMoney"></div>
        </div>
        <div class="scroll re_sizing">
            <div class="scroll_title re_flex">
                <div class="tag1" @click="getList(1)">
                    <img v-if="titleIndex == 1" class="tag1_img" src="../../../assets/activity/tag1.png" alt="">
                    <img v-else class="tag2_img" src="../../../assets/activity/tag2.png" alt="">
                </div>
                <div class="tag2" @click="getList(2)">
                    <img v-if="titleIndex == 1" class="tag2_img" src="../../../assets/activity/tag3.png" alt="">
                    <img v-else class="tag1_img" src="../../../assets/activity/tag4.png" alt="">
                </div>
            </div>
            <div class="scroll_content">
                <div v-if="listData.length > 0" v-for="item in listData" class="item re_margin_bot_8 re_flex_between re_font_bold">
                    <div class="item_left">
                        <div class="re_font_16">{{ titleIndex == 1 ? item.label : '提现至支付宝' }}</div>
                        <div class="re_font_12">{{ item.createTime }}</div>
                    </div>
                    <div class="item_right">
                        <div class="re_font_16">+{{ titleIndex == 1 ? item.amount : item.money }}</div>
                        <div class="re_font_12">{{ titleIndex == 1 ? item.expireWarn : item.auditStatusStr }}</div>
                    </div>
                </div>
                <p v-else class="re_font_14 re_flex_center">暂无数据</p>
            </div>
        </div>
        <div v-show="showMask" class="mask re_absolute re_sizing re_flex_center">
            <div>
                <div class="ruleBg re_sizing">
                    <div class="rule re_sizing">
                        <div v-html="ruleInfoData.ruleContent"></div>
                    </div>
                </div>
                <img @click="showMask = false" class="re_relative close"
                    src="https://tp.kaishuihu.com/dsgc/icon/static/image/image/close.png" alt="">
            </div>
        </div>
        <div class="maskShare" @click="showShare = false" v-show="showShare">
            <img src="../../../assets/activity/maskShare.png" alt="">
        </div>
    </div>
</template>

<script setup>

import { getInfo, expireWarn, ruleInfo, list, getDrawList, applyDraw } from './api.js';
import { ref, onMounted } from 'vue';
import { showDialog, showConfirmDialog } from 'vant';
// import { shareConfig } from '../wechat/api.js';

const titleIndex = ref(1);
const showMask = ref(false);
const showShare = ref(false);
const infoData = ref({});
const expireWarnData = ref({});
const ruleInfoData = ref({});
const listData = ref([]);
const isWechat = ref(false);
const getInfoData = () => {
    const os = localStorage.getItem('os')
    titleIndex.value = 1;
    if (os === 'wxapplet_happy_group') {
        isWechat.value = true;
    }
    getInfo().then(res => {
        infoData.value = res.data;
    })
    expireWarn().then(res => {
        expireWarnData.value = res.data;
    })
    ruleInfo().then(res => {
        ruleInfoData.value = res.data;
    })
    list().then(res => {
        listData.value = res.data;
    })
    // getShareConfig();

}
// const getShareConfig = () => {
//     shareConfig().then(res =>{
//         if(res.code == 1){
//             wx.config({
//                 debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
//                 appId: res.data.appid, // 必填，公众号的唯一标识
//                 timestamp: res.data.timestamp, // 必填，生成签名的时间戳
//                 nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
//                 signature: res.data.signature,// 必填，签名
//                 jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'onShareTimeline'], // 必填，需要使用的JS接口列表
//             });
//             wx.ready(function () {
//                 console.log('ready')
//             })
//             wx.error(function(res){
//                 alert(JSON.stringify(res))
//             // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
//             });
//         }
//     })
// }
// 分享
const shareWechat = () => {
    if (isWechat.value) {
        showShare.value = true;
    } else {
        goBack(3)
    }
}
const goBack = (type) => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        if (type == 1) appJsImp.finish()   // 返回
        if (type == 2) appJsImp.toBindAlipay()   // 绑定支付宝
        if (type == 3) appJsImp.toShare()   // 分享
    }
    if (isiOS) {
        if (type == 1) window.webkit.messageHandlers.AppModel.postMessage('finish');
        if (type == 2) window.webkit.messageHandlers.AppModel.postMessage('toBindAlipay');
        if (type == 3) window.webkit.messageHandlers.AppModel.postMessage('toShare');
    }
}
getInfoData();
const getList = (index) => {
    titleIndex.value = index;
    listData.value = [];
    let url = index == 1 ? list : getDrawList;
    let params = {};
    if (index == 2) {
        params.rulesType = 5,
        params.pageSize = 999
    }
    url(params).then(res => {
        listData.value = res.data;
    })
}
const loading = ref(false); // 限制800ms重复点击
const getMoney = () => {
    if (loading.value) return;
    loading.value = true;
    setTimeout(() => {
        loading.value = false;
    }, 800)
    if (isWechat.value) {
        showDialog({ title: '提示', message: '请下载开水壶App进行提现' });
    } else {
        if(infoData.value.withdrawingAmount == 0){
            showConfirmDialog({
                    title: '提示',
                    message: '暂无可提现金额，可刷新页面后重试！',
                }).then(res => {
                    if (res === 'confirm') {
                        getInfo().then(res => {
                            infoData.value = res.data;
                        })
                    }
                })
            return;
        }
        getInfo().then(res => {
            if (res.data.isAlipay == 1) {
                applyDraw({
                    rulesType: 5,
                    money: infoData.value.withdrawingAmount,
                    withdrawType: 1
                }).then(res => {
                    showDialog({ title: '提示', message: res.msg });
                    getInfoData()
                })
            } else {
                showConfirmDialog({
                    title: '提示',
                    message: '请先完成支付宝绑定后进行提现'
                }).then(res => {
                    if (res === 'confirm') {
                        goBack(2)
                    }
                })
            }
        })


    }
}
</script>

<style lang="less" scoped>

.bgnormal {
    background: url('https://tp.kaishuihu.com/dsgc/icon/static/bg.png') no-repeat left top;
    background-size: 100% 812px;

}

.bgWechat {
    background: url('https://tp.kaishuihu.com/dsgc/icon/static/wechatBg.png') no-repeat left top;
    background-size: 100% 812px;
}

.maskShare {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    z-index: 9;
    img{
        width: 100vw;
        height: 100vh;
    }
}

.bg {
    width: 100%;
    min-height: 100vh;
    padding-top: 40px;
    background-color: #EBE0E9 !important;

    .title {
        width: 100%;

        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }

    .commonP {
        top: 62px;
        width: 84px;
        height: 24px;
    }

    .commonW {
        top: 12px;
        width: 84px;
        height: 24px;
    }

    .share {
        right: 0;
    }

    .shareBtn {
        z-index: 10;
        width: 40px;
        right: 40px;
    }

    .ruleBtn {
        z-index: 10;
        right: 0;
        width: 40px;
    }

    .content {
        width: 100%;
        height: 314px;
        background: url('../../../assets/activity/btn.png') no-repeat center center;
        background-size: 100% 100%;
        padding: 78px 50px 0 50px;
        color: #FF5959;

        .item {
            width: 50%;
            text-align: center;
            height: 70px;

            .money {
                font-size: 32px;
            }

            .re_font_14 {
                margin-top: 6px;
            }
        }

        .submit {
            width: 210px;
            height: 60px;
            margin: 6px auto 0 auto;
        }
    }
}
.scroll {
    width: 96%;
    margin: 0 auto;
    background-color: #FFECBD;
    border-radius: 20px;
    padding: 20px 0 10px 0;
    min-height: 260px;

    img {
        display: block;
        width: 64px;
        margin: 0 auto;
    }

    .tag1,
    .tag2 {
        width: 50%;
    }

    .tag1_img {
        height: 22px;
    }

    .tag2_img {
        height: 16px;
    }

    .scroll_content {
        padding: 0 20px;
        margin-top: 10px;
    }

    .re_font_12 {
        color: #98524E;
        margin-top: 4px;
    }

    .item {
        height: 50px;
    }

    .item_left .re_font_16 {
        color: #70120D;
    }

    .item_right {
        color: #FA3128;
        text-align: right;

        .re_font_12 {
            color: #FF9725;
        }
    }
}

.tips {
    text-align: center;
    margin-top: 46px;
    height: 20px;
}

.mask {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    text-align: center;
    position: fixed;

    .ruleBg {
        width: 280px;
        height: 354px;
        background: url('https://tp.kaishuihu.com/dsgc/icon/static/rule.png') no-repeat center center;
        background-size: 100% 100%;
        padding-top: 60px;
    }

    .rule {
        position: relative;
        z-index: 2;
        padding: 0 20px;
        width: 280px;
        height: 252px;
        overflow-y: auto;
        text-align: left;
        div{
            font-size: 14px;
            line-height: 18px;
            color: #FF7880;
        }
    }

    .close {
        top: 30px;
        width: 30px;
        height: 30px;
    }
}
</style>